import { KJUR } from "jsrsasign"
import { v4 } from "uuid"

const EXPIRED_IN = 2
const TOKEN_ALGORITHM = 'HS256'
const TOKEN_AUDIENCE = 'tableau'
const TOKEN_SCOPE = ["tableau:views:embed","tableau:metrics:embed"]

export function generateToken() {
  const exp = Math.floor(new Date(new Date().getTime() + 60000 + EXPIRED_IN).getTime()/1000)
  const jti = v4()
  const sub = process.env.REACT_APP_USER
  const iss = process.env.REACT_APP_CLIENT_ID
  const kid = process.env.REACT_APP_SECRET_ID
  const hash = "oU7l90GHHzmy/iYd4ytodv0Gyrr7cOoGYT1hvZLbr8o="

  if(!sub || !iss || !kid || !hash) {
    throw(new Error(`Could not generate token. There is missing one of the following properties: [${sub ? '' : 'REACT_APP_USER '}${iss ? '' : 'REACT_APP_CLIENT_ID '}${kid ? '' : 'REACT_APP_SECRET_ID '}${hash ? '' : 'REACT_APP_SECRET_VAL'}].`))
  }

  const header = { 
    alg: TOKEN_ALGORITHM, 
    typ: 'JWT',
    kid, 
    iss
  }

  const payload = { iss, sub, exp, jti, aud: TOKEN_AUDIENCE, scp: TOKEN_SCOPE }

  return KJUR.jws.JWS.sign(
    TOKEN_ALGORITHM, 
    JSON.stringify(header),
    JSON.stringify(payload),
    hash
  )
}